
.SectionDivider__section-container{
    /* border: 2px solid red; */
    background-color: #FFDBE1;
    padding: .5em;
}
    .SectionDivider__inner-container{
        /* border: 3px solid green; */
        display: flex;
    }
        .SectionDivider__title-container{
            /* border: 3px solid blue; */
            display: flex;
            justify-content: center;
            margin-top: 1.5em;
            margin-bottom: 1.5em;

        }   
            .SectionDivider__title{
                /* border: 3px solid red; */
                color: #FFF;
                text-align: center;
                font-family: Montserrat;
                font-size: 2.25rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.05rem; 
            }
        .SectionDivider__icons-container{
                /* border: 3px solid green; */
                display: flex;
        }
            .SectionDivider__inner-icons-container p {
                color: black;
                text-align: center;
                font-family: Montserrat;
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: uppercase;
                margin-bottom: 0;
                margin-top: 1em;
                            }
            .SectionDivider__inner-icons-container{
                /* border: 3px solid purple; */
                display: flex;
                
            }
                .SectionDivider__icon-container{
                    /* border: 3px solid green; */
                    width: 4em;
                    margin-right: 1.5em;
                }
                    .SectionDivider__icon{
                        /* border: 3px solid red; */
                        width: 100%;
                    }

@media (max-width: 575.98px) {
   .SectionDivider__section-container{
        /* border: 3px solid red; */
        padding: 1.5em;
      
   }.SectionDivider__inner-container{
        /* border: 3px solid green;  */
        display: flex;
        flex-direction: column;
        align-items: center;

    }.SectionDivider__icons-container{
            /* border: 3px solid blue; */
            width: 80%;
            display: flex;
            flex-direction: column;
        }
        .SectionDivider__inner-icons-container{
            /* border: 3px solid red; */
            margin-top: 1.3em;
        }
        .SectionDivider__icon-container{
            /* border: 3px solid red; */
            width: 100%;
            max-width: 3em;
        }.SectionDivider__icon-container div{
            /* border: 3px solid blue; */
        }.SectionDivider__icon{
            /* border: 3px solid black; */
        }

    .SectionDivider__text-container{
        /* border: 3px solid green; */
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
        .SectionDivider__text-container p{
            /* border: 3px solid red; */
            margin: 0;
            padding: 0;
            font-size: 1rem;
        }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .SectionDivider__section-container{
        /* border: 3px solid red; */
        padding-top: 2em;
        padding-bottom: 2em;

      
   }.SectionDivider__inner-container{
        /* border: 3px solid rgb(0, 0, 0); */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-left: 2em;
       
    }
        .SectionDivider__icons-container{
            /* border: 3px solid blue; */
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
        }
            .SectionDivider__inner-icons-container{
                /* border: 3px solid rgb(0, 215, 0); */
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-top: 1.3em;
                
            }
            /*test*/
            .SectionDivider__icon-container{
                /* border: 3px solid green; */
               
            }
            .SectionDivider__icon-container div{
                /* border: 3px solid red; */
                width: 4em;
            }
            .SectionDivider__icon{
                width: 100%;
            }
            .SectionDivider__text-container{
                /* border: 3px solid black; */
                width: 100%;
            }

            /*end test*/
            .SectionDivider__inner-icons-container p {
                font-size: 1rem;  
            }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .SectionDivider__section-container{
        /* border: 3px solid red; */
        padding-top: 2em;
        padding-bottom: 2em;
      
   }.SectionDivider__inner-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 2em;
       
    }
        .SectionDivider__icons-container{
            /* border: 3px solid blue; */
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
        }
            .SectionDivider__inner-icons-container{
                /* border: 3px solid rgb(0, 215, 0); */
                width: 50%;
                margin-top: 1.3em;
            }

            .SectionDivider__icon-container{
                /* border: 3px solid green; */
               
            }
            .SectionDivider__icon-container div{
                /* border: 3px solid red; */
                width: 4em;
            }
            .SectionDivider__icon{
                width: 100%;
            }
            .SectionDivider__text-container{
                /* border: 3px solid black; */
                width: 100%;
            }

}


@media (min-width: 992px) and (max-width: 1199.98px) {

    .SectionDivider__icon-container{
        margin: 0;
    }
    .SectionDivider__section-container{
        /* border: 3px solid red; */
        padding-top: 2em;
        padding-bottom: 2em;
      
   }.SectionDivider__inner-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: row;
        align-items: center;
       
    }
        .SectionDivider__icons-container{
            /* border: 3px solid blue; */
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            padding: 0;
            margin: 0;
        }
            .SectionDivider__inner-icons-container{
                /* border: 3px solid rgb(0, 215, 0); */
                width: 15%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0;
                margin: 0;
            }
}

@media (min-width: 1200px) {
    .SectionDivider__inner-icons-container{
        /* border: 3px solid red; */
        margin-left: 1em;
        margin-right: 1em;
    }
    .SectionDivider__icon-container{
        margin: 0;
    }
    .SectionDivider__section-container{
        padding: 5em 7em 5em 7em;
        /* border: 3px solid red; */
        margin: 0;
    }
    .SectionDivider__title-container{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 0;
        padding: 0;
        margin-bottom: 2.6em;
    }
    .SectionDivider__inner-container{
        display: flex;
        flex-direction: column; 
    }
    .SectionDivider__icons-container{
        flex-direction: row;
        justify-content: space-around;
    }
    .SectionDivider__inner-icons-container{
        flex-direction:column;
        justify-content: center;
        align-items: center;
    }
}