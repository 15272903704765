
.Benefits__title-section-container{
    /* border: 2px solid red; */
    background-color: #04BF7B;
    height: auto;
}
    .Benefits__title-section-container h1{
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.05rem;   
        height: auto;
        padding-top:.5em;
        padding-bottom: .5em;
    }

@media (max-width: 575.98px) {
    .Benefits__title-section-container h1{
        font-size: 2rem;
        font-weight: 700;
    }
    .Benefits__benefit-list-component-container{
        margin-top: 2em;
        margin-bottom: 2em;
        padding: 1.6em;
    }
}
        

    
@media (min-width: 576px) and (max-width: 767.98px) {
    .Benefits__benefit-list-component-container{
        margin-top: 2em;
        margin-bottom: 2em;
        padding: 1.6em;
    }
    .Benefits__title-section-container h1{
        font-size: 2rem;
        font-weight: 700;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .Benefits__benefit-list-component-container{
        margin-top: 2em;
        margin-bottom: 2em;
        padding: 1.6em;
    }
    .Benefits__title-section-container h1{
        font-size: 2rem;
        font-weight: 700;
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .Benefits__benefit-list-component-container{
        margin-top: 2em;
        margin-bottom: 2em;
        padding: 1.6em;
    }
    .Benefits__title-section-container h1{
        font-size: 2.2rem;
        font-weight: 700;
    }
}


@media (min-width: 1200px) {
    .Benefits__title-section-container h1{
        font-size: 2.5rem;
        font-weight: 700;
    }
}