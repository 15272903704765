.call-us-button{
    background-color: white;
    border-radius: 3%;
    border-color: #9741c7;
    color: #9741c7;
    font-size: 1.5rem;
    font-weight: 16rem;
    width: 10em;
}   
.CallUsButton__cellphone{
    text-decoration: none !important;
}
.CallUsButton__cellphone:hover{
    text-decoration: none !important;
}