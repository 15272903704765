.main-navigation__menu-btn {
  width: 4rem;
  height: 3rem;
  background: #C2261C;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 1.5rem;
  cursor: pointer;
  padding: .6em;
  border-radius: .4em .4em .4em .4em;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: white;
}

.main-navigation__title {
  color: white;
  margin-left: .5em;
}

.main-navigation__title a {
  text-decoration: none;
  color: white;
}

.main-navigation__header-nav {
  display: none;

}

.main-navigation__drawer-nav {
  height: 100%;
}

.MainNavigation__main-social-media-container{
  width: 6em;
  margin-right: 0em;
  margin-left: 1em;
} 
  .MainNavigation__inner-social-media-container{ 
      display: flex;
      flex-direction: row;
      justify-content: space-around;
}


.Navigation__logo{
  cursor: pointer;
}


/*================== FULL RESPONSIVE MODE ============*/

@media (max-width: 320px){
  .main-navigation__logo-container{
    /* border: 3px solid green; */
    width: 100%; 
    margin-bottom: 0;   
  }
    .Navigation__logo{
      /* border: 4px solid purple; */
      width: 100%;
    }
  .MainNavigation__main-social-media-container{
    /* border: 3px solid blue; */
    width: 70%;
    
  }
  .MainNavigation__inner-social-media-container{
    /* border: 3px solid red; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
    .Header__social-logo{
      /* border: 3px solid yellow; */
      padding-left: .2em;
      padding-right: .2em;
      width: 100%;
    }
  .main-navigation__menu-btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    max-height: 2.5em;
    min-width: 2em;
    max-width: 2.5em;
  }
    .main-navigation__menu-btn span{
      height: .1em;
      margin: .1em;
      width: 100%;
      max-width: 2em;
    }
}

@media (max-width: 575.98px) {
    .main-navigation__title{
      width: 7em; 
      margin-bottom: 0;    
    }
      .Navigation__logo{
        width: 100%;
      }
    .main-navigation__menu-btn{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .main-navigation__menu-btn{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: .4em;
      width: 100%;
      max-width: 3.5em;

    }
    .main-navigation__menu-btn span{
      height: .1em;
      width: 2.5em;
    }
    .Header__social-logo{
      margin: .2em;
    }
   
}


@media (min-width: 576px) and (max-width: 767.98px) {
  .main-navigation__menu-btn{
  }
    .main-navigation__logo-container{
      width: 12em; 
      margin-bottom: 0;    
    }
    .main-navigation__menu-btn{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .main-navigation__menu-btn span{
      height: .15em;
      /* width: 2.5em; */
    }
      .Navigation__logo{
        width: 100%;
      }
}


@media (min-width: 768px) and (max-width: 991.98px) {
  .main-navigation__title {
    color: white;
    margin-left: 1.1em;
    width: 7em; 
    margin-bottom: 0;   
  }
  .Navigation__logo{
    width: 100%;
  } 
  .main-navigation__menu-btn {
    display: flex;
    flex-direction: column;
      align-items: center;
  }

  .main-navigation__header-nav {
    display: none;

  }

  .MainNavigation__main-social-media-container{
    width: 6em;
    margin-right: 2em;
  } 
    .MainNavigation__inner-social-media-container{ 
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .main-navigation__logo-container{
    color: white;
    margin-left: 1.1em;
    width: 10em; 
    margin-bottom: 0;   
  }
  .Navigation__logo{
    width: 100%;
  }
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }

  .main-navigation__logo-container{
    color: white;
    margin-left:0;
    padding: 0;
  }
}


@media (min-width: 1200px) {
  .main-navigation__logo-container{
    color: white;
    margin-left: 1.1em;
    width: 12em; 
    margin-bottom: 0;   
  }
  .Navigation__logo{
    width: 100%;
  }
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }

  .main-navigation__logo-container{
    color: white;
    margin-left:0;
    padding: 0;
  }
}



