
.AvailablePlan__main-section-container{
    margin-top: 3em;
}
    .AvailablePlan__title{
        color: #000;
        text-align: center;
        font-family: Montserrat;
        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.05rem; 
    }

    .AvailablePlan__components-container{
        padding-top: 2em;
        display: flex;
    }
    .AvailablePlan__subscriptionCard{
        display: flex;
    }
    .AvailablePlan__contact-card-container{
        margin-top: 5em;
        margin-bottom: 3.5em;
    }





@media (max-width: 575.98px) {
    .AvailablePlan__main-section-container{
        /* border: 3px solid blue; */
    }
    .AvailablePlan__components-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        /* border: 3px solid green; */
    }
    .AvailablePlan__subscriptionCard{
        display: flex;
        flex-direction: column;
        align-items: center;
        /* border: 3px solid green; */
        width: 100%;
    }
    .AvailablePlan__title-container{
        padding: 1.5em;
    }
    .AvailablePlan__title{
        line-height: 1em;
    }
    .AvailablePlan__contact-card-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .AvailablePlan__components-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    .AvailablePlan__subscriptionCard{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .AvailablePlan__title{
        line-height: 1em;
    }
    .AvailablePlan__contact-card-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .AvailablePlan__components-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    .AvailablePlan__subscriptionCard{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
    }
    .AvailablePlan__title{
        line-height: 1em;
    }
    .AvailablePlan__contact-card-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .AvailablePlan__components-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    .AvailablePlan__subscriptionCard{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
    }
    .AvailablePlan__title{
        line-height: 1em;
    }
    .AvailablePlan__contact-card-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}

@media (min-width: 1200px) {
    .AvailablePlan__components-container{
        flex-direction: column;
        align-items: center;
    }   
    .AvailablePlan__subscriptionCard{
        flex-direction: row;
   
    }
}