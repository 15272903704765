.SectionMainForm {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f7f6;
}

.SectionMainForm h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.FormObjects {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
}

.LabelForms {
    display: block;
    margin-bottom: 8px;
    margin-top: 20px;
    color: #333;
    font-size: 16px;
}

.InputForms {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box; /* Makes sure padding doesn't affect the overall width */
}

input[type="submit"] {
    background-color: #4CAF50;
    color: white;
    padding: 
}
.ContactUs__main-container{
    /* border: 3px solid red; */
}
    .ContactUs__contact-information-container{
        /* border: 3px solid blue ; */
    }
        .ContactUs__contact-information{
            /* border: 3px solid green; */
        }

        
    .ContactUs__contact-form-container{
        /* border: 2px solid purple    ; */
    }
        .ContactUs__contact-form{
            /* border: 3px solid red; */
        }


        @media (max-width: 360px){
            .ContactUs__contact-form-container{
                /* border: 3px solid purple; */
                width: 100%;
                padding: 0;
                margin: 0;
            }
            .ContactUs__contact-form{
                /* border: 2px solid yellow; */
                border-radius: 1.25rem;
                padding: 0 !important;
                /* background-color: #E6E5E5; */
            }
        }


@media (max-width: 575.98px) {
    .ContactUs__main-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .ContactUs__contact-information-container{
            /* border: 3px solid red; */
        } 
            .ContactUs__contact-information{
                /* border: 3px solid blue; */
                padding: 2.5em;
            } 
        .ContactUs__contact-form-container{
            /* border: 3px solid purple; */
            width: 100%;
            padding: .2em;
        }
            .ContactUs__contact-form{
                /* border: 2px solid yellow; */
                border-radius: 1.25rem;
                padding: 1em 2em 1em 2em;
                /* background-color: #E6E5E5; */
            }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .ContactUs__main-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .ContactUs__contact-information-container{
            /* border: 3px solid red; */
        } 
            .ContactUs__contact-information{
                /* border: 3px solid blue; */
                padding: 2.5em;
            } 
        .ContactUs__contact-form-container{
            /* border: 3px solid purple; */
            width: 100%;
            padding: 1.1em;
        }
            .ContactUs__contact-form{
                /* border: 2px solid yellow; */
                border-radius: 1.25rem;
                padding: 1em 2em 1em 2em;
                /* background-color: #E6E5E5; */
            }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .ContactUs__main-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .ContactUs__contact-information-container{
            /* border: 3px solid red; */
        } 
            .ContactUs__contact-information{
                /* border: 3px solid blue; */
                padding: 2.5em;
            } 
        .ContactUs__contact-form-container{
            /* border: 3px solid purple; */
            width: 100%;
            padding: 1.1em;
        }
            .ContactUs__contact-form{
                /* border: 2px solid yellow; */
                border-radius: 1.25rem;
                padding: 1em 2em 1em 2em;
                /* background-color: #E6E5E5; */
            }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .ContactUs__main-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
        .ContactUs__contact-information-container{
            /* border: 3px solid red; */
        } 
            .ContactUs__contact-information{
                /* border: 3px solid blue; */
                padding: 2.5em;
            } 
        .ContactUs__contact-form-container{
            /* border: 3px solid purple; */
            width: 100%;
            padding: 0em;
        }
            .ContactUs__contact-form{
                /* border: 2px solid yellow; */
                border-radius: 1.25rem;
                padding: 1em 2em 1em 2em;
                /* background-color: #E6E5E5; */
            }

}

@media (min-width: 1200px) {
    .ContactUs__main-container{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 5em;
        margin-bottom: 10em;
    }
    .ContactUs__contact-information{
        /* border: 3px solid blue; */
        padding: 2.5em;
    } 
        .ContactUs__contact-information-container{
            /* border: 3px solid blue ; */
            width: 40em;
        }
        .ContactUs__contact-form-container{
            width: 35em;
            /* border: 3px solid green; */
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 1.25rem;
            padding: 1em 4em 1em 4em;
            /* background-color: #E6E5E5; */
        }
        .ContactUs__contact-form-container{
            margin-right: 1.5em;
        }

}