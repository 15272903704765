
.SubscriptionCard__main-container{
    margin: 0;
    padding: 0;
    /* border: 3px solid red; */
    width: 100%;
    max-width: 25em;
  }
      .SubscriptionCard__inner-container{
          /* border: 1px solid green; */
          display: flex;
          flex-direction: column;
          align-items: center;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); 
          border-radius: 1.25rem;
          background: #FFF;
          padding: 1.5em 1em .6em 1em;
          margin-top: 2em;
          width: 100%;
      }
          .SubscriptionCard__card-title h1{
              color: #C2261C;
              text-align: center;
              font-family: Montserrat;
              font-size: 3rem;
              font-style: normal;
              font-weight: 700;
              line-height: 1.05rem;
              text-transform: uppercase;
              margin-bottom: .2em;
              margin-top: .3em;
              /* border: 2px solid red; */
          }
          .SubscriptionCard__card-trial-info{
            /* border: 2px solid red; */
            margin-top: 2em;
          }
            .SubscriptionCard__card-trial-info p{
                margin-bottom: 0;
                color: #000;
                font-family: Lato;
                font-size: 1.3rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.05rem;
            }
    
          .SubscriptionCard__card-price{
              margin-top: 3em;
              /* border: 3px solid red; */
  
          }
              .SubscriptionCard__dolar-price{
                  color: #000000;
                  text-align: right;
                  font-family: Montserrat;
                  font-size: 4.5rem;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 1.05rem;
                  text-transform: uppercase;
                  /* border: 2px solid black; */
              }
              .SubscriptionCard__cents-price{
                  color: #000000;
                  font-family: Montserrat;
                  font-size: 2rem;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 1.05rem; 
                  text-transform: uppercase;
                  /* border: 2px solid red; */
                  margin-bottom: 3em;
              }
  
  
          .SubscriptionCard__card-description{
              /* border: 3px solid yellow; */
          }
              .SubscriptionCard__card-description p{
                  color: #000;
                  text-align: center;
                  font-size: 1.6rem;
                  font-style: normal;
                  font-weight: 300;
                  line-height: normal;
              }
  
  
          .SubscriptionCard__button-container{
              display: flex;
              flex-direction: row;
              justify-content: center;
              width: 80%;
          }
              .SubscriptionCard__button{
                  width: 100%;
              }
                  .SubscriptionCard__button button{
                      width: 100%;
                      background-color: rgba(255, 255, 255, 1);
                      border: 1px solid #7D12B9;
                      border-radius: 1rem;
                      padding: .5rem  0.3125rem;
                      text-align: center;
                      font-family: Montserrat;
                      font-size: 1.1rem;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 1.5rem;
                      text-transform: uppercase;
                      color: #7D12B9;
                      margin-bottom: 2em;
                  }
                  .SubscriptionCard__button button:hover{
                      width: 100%;
                      background-color: #7D12B9;
                      color:#ffffff;
                      border-radius: 1rem;
                      padding: .5rem  0.3125rem;
                      text-align: center;
                      font-family: Montserrat;
                      font-size: 1.1rem;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 1.5rem;
                      text-transform: uppercase;
                  }
  
                  .SubscriptionCard__subscription-definition-container{
             
                      padding: 1.3em;
                      margin: 0;
                      max-width: 22em;
                  
                  }
                      .SubscriptionCard__subscription-definition-container p{
                              margin-top: .4em;
                              color: #00030A;
                              font-family: Lato;
                              font-size: 1rem;
                              font-style: normal;
                              font-weight: 300;
                              line-height: 2.25rem; 
                      }
  
  
  
  
  /*=========== RESPONSIVE ============*/
  
  @media (max-width: 575.98px) {
  
      .SubscriptionCard__main-container{
          padding: 0;
          margin: 0;
          padding: 1em;
          /* border: 3px solid red; */
          display: flex;
          flex-direction: column;
          align-items: center;
        }
       
              .SubscriptionCard__card-container {
                  width: 100%;
                  max-width: 20em;
                  /* border: 3px solid blue; */
              }
  }
  
  
  @media (min-width: 576px) and (max-width: 767.98px) {
    .SubscriptionCard__button{
        /* border: 3px solid red; */
        width: 70%;
    }
      .SubscriptionCard__main-container{
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 1em;
        }
      .SubscriptionCard__main-container{
          /* border: 2px solid red; */
          margin-right: 1.5em;
          margin-left: 1.5em;
          /* border: 6px solid yellow; */
          height: auto;
      }
          .SubscriptionCard__card-container{
              /* border: 2px solid blue; */
              width: 100%;
              height: auto;
              
          }
  }
  
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    .SubscriptionCard__main-container{
        margin-left: .5em;
        margin-right: .5em;
    }
    .SubscriptionCard__button{
        width: 70%;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1199.98px) {
  
    .SubscriptionCard__main-container{
        margin-left: 1em;
        margin-right: 1em;
    }

    .SubscriptionCard__button{
        width: 70%;
    }
  }
  
  
  @media (min-width: 1200px) {
    .SubscriptionCard__main-container{
        margin-left: 2em;
        margin-right: 2em;
    }
    .SubscriptionCard__button{
        width: 70%;
    }
  }