.ContactForm__button-container{
    /* border: 2px solid red; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
    .ContactForm__btn{
        border-radius: 1.25rem;
        color:  var(--secundary-color);
        text-align: center;
        font-family: 'Roboto Mono', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5rem; /* 100% */
        text-transform: uppercase;
        border: 1px solid var(--secundary-color);
        display: flex;
        padding: .6rem 2.5rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.25rem;
        background-color: #e6e5e5;
    }
        .ContactForm__send-btn{
            color: rgb(255, 255, 255);
            background-color:#e6e5e5;
        }
label{
    color: #000;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    /* border: 3px solid red; */
    margin-bottom: 0;
    
   
}

.form-control[type="text"] {
    border: 1px solid #ccc;
    padding: 5px;
    padding: 0.625rem 0.9375rem;
    /* border: 3px solid green; */
    margin-top:.5em;
  }
  .form-control[type="email"] {
    border: 1px solid #ccc;
    padding: 5px;
    padding: 0.625rem 0.9375rem;
    /* border: 3px solid green; */
    margin-top: .5em;
  }
  .form-control[type="select"] {
    border: 1px solid #ccc;
    padding: 5px;
    padding: 0.625rem 0.9375rem;
    /* border: 3px solid green; */
    margin-top:.5em;
  }
  .ContactForm__main-form-container{
    background-color: #e6e5e5;
    padding: 2em;
    border-radius: 1.25rem;
    /* border: 3px solid red; */
  }

  .ContactForm__recaptcha-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: .5em;
  }
  
  @media(max-width: 360px ){
    .ContactForm__main-form-container{
        /* border: 3px solid blue; */
        width: 100%;
        
    }
  }

@media (max-width: 575.98px) {

    .ContactForm__main-form-container{
        /* border: 5px solid blue; */
        width: 100%;
    }
    .ContactForm__button-container{
        /* border: 2px solid red; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
        .ContactForm__btn{
            border-radius: 1.25rem;
            /* color:  #04BF7B; */
            text-align: center;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.5rem; /* 100% */
            text-transform: uppercase;
            display: flex;
            padding: .6rem 2.5rem;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1.25rem;
        }
            .ContactForm__send-btn{
                color: rgb(255, 255, 255);
                /* background-color: #04BF7B; */
            }
    label{
        color: #000;
        font-family: Montserrat;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        /* border: 3px solid red; */
        margin-bottom: 0;
        
       
    }
    
    .form-control[type="text"] {
        border: 1px solid #ccc;
        padding: 5px;
        padding: 0.625rem 0.9375rem;
        /* border: 3px solid green; */
        margin-top:.5em;
      }
      .form-control[type="email"] {
        border: 1px solid #ccc;
        padding: 5px;
        padding: 0.625rem 0.9375rem;
        /* border: 3px solid green; */
        margin-top: .5em;
      }

      .ContactForm__main-button-container{
        /* border: 3px solid blue; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
    }
    .ContactForm__button-container button {
        width: 100%;
    }
    .ContactForm__button-container{
        /* border: 3px solid red; */
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .ContactForm__button-container{
        /* border: 2px solid red; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
        .ContactForm__btn{
            border-radius: 1.25rem;
            text-align: center;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.5rem; /* 100% */
            text-transform: uppercase;
            /* border: 1px solid #04BF7B; */
            display: flex;
            padding: .6rem 2.5rem;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1.25rem;
        }
            .ContactForm__send-btn{
                color: rgb(255, 255, 255);
            }
    label{
        color: #000;
        font-family: Montserrat;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        /* border: 3px solid red; */
        margin-bottom: 0;
        
       
    }
    
    .form-control[type="text"] {
        border: 1px solid #ccc;
        padding: 5px;
        padding: 0.625rem 0.9375rem;
        /* border: 3px solid green; */
        margin-top:.5em;
      }
      .form-control[type="email"] {
        border: 1px solid #ccc;
        padding: 5px;
        padding: 0.625rem 0.9375rem;
        /* border: 3px solid green; */
        margin-top: .5em;
      }
      .ContactForm__main-button-container{
        /* border: 3px solid blue; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
    }
    .ContactForm__button-container{
        /* border: 3px solid red; */
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {

}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .ContactForm__main-form-container{
        margin: 0;
    }
    .ContactForm__main-button-container{
    
        min-width: 20em;
    }
    
}

@media (min-width: 1200px) {

        .ContactForm__button-container{
            /* border: 2px solid red; */
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
            .ContactForm__btn{
                border-radius: 1.25rem;
                text-align: center;
                font-size: 1rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.5rem; /* 100% */
                text-transform: uppercase;
                display: flex;
                padding: .6rem 2.5rem;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1.25rem;
            }
                .ContactForm__send-btn{
                    color: rgb(255, 255, 255);
                }
        label{
            color: #000;
            font-family: Montserrat;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            /* border: 3px solid red; */
            margin-bottom: 0;
            
           
        }
        
        .form-control[type="text"] {
            border: 1px solid #ccc;
            padding: 5px;
            padding: 0.625rem 0.9375rem;
            /* border: 3px solid green; */
            margin-top:.5em;
          }
          .form-control[type="email"] {
            border: 1px solid #ccc;
            padding: 5px;
            padding: 0.625rem 0.9375rem;
            /* border: 3px solid green; */
            margin-top: .5em;
          }
}