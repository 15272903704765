
.SectionInfo__main-section-container{
    padding-left: 2em;
    padding-right: 2em;
    /* border: 3px solid green; */
}
    .SectionInfo__title-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #04B2D9;
        width: 100%;
    }
        .SectionInfo__title{
            color: #000000;
            text-align: center;
            font-family: Montserrat;
            font-size: 4rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.05rem;
        }


@media (max-width: 575.98px) {
    .SectionInfo__main-section-container{
        margin: 0;
        padding: 0;
        /* border: 3px solid red; */
        width: 100%;
    }
    .SectionInfo__title-container{
        /* border: 3px solid blue; */
        width: 100%;
        padding: 1em;
    }
    .SectionInfo__title{
        color: #000000;
        text-align: center;
        font-family: Montserrat;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.5rem;
    }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .SectionInfo__main-section-container{
        margin: 0;
        padding: 0;
        width: 100%;
    }
    .SectionInfo__title{
        line-height: 2.5rem;
        width: 100%;
        padding: .3em;
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .SectionInfo__main-section-container{
        margin: 0;
        padding: 0;
        width: 100%;
    }
    .SectionInfo__title{
        line-height: 2.5rem;
        width: 100%;
        padding: .3em;
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .SectionInfo__main-section-container{
        margin: 0;
        padding: 0;
        width: 100%;
    }
    .SectionInfo__title{
        line-height: 2.5rem;
        width: 100%;
        padding: .3em;
    }

}

@media (min-width: 1200px) {
    .SectionInfo__main-section-container{
        margin: 0;
        padding: 0;
        width: 100%;
    }
    .SectionInfo__title{
        line-height: 2.5rem;
        width: 100%;
        padding: .5em;
    }
}
        