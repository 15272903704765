.MainFooter__footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #FFDBE1;
    /* border: 3px solid red; */
}
.MainFooter__main-footer-container{
    /* border: 3px solid rgb(0, 0, 0); */
    display: flex;
    flex-direction: row; 
    justify-content: center;   
    align-items: center;
    width: 55%;
    /* border: 8px solid red; */
}
.MainContainer__right-separator{
    border-right: 3px solid rgb(0, 0, 0);
    height: 10em;
}
    .MainFooter__left-container{
        /* border: 10px solid green; */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 30%;
        padding: .3em;
        margin-left: 2em;

        
    }
        
        .MainFooter__icons{
            width: 1.5em;
            height: 1.5em;
            margin-right: .5em;
            margin-bottom: .3em;
            /* border: 2px solid red; */
        }
        .MainFooter__left-logo-container{
           /* border: 3px solid blue;  */
           margin-bottom: .6em;
        }
            .MainFooter__logo{
                width: 10em;
                height: 2.6em;
            }
        .MainFooter__left-contact-container{
            display: flex;
            flex-direction: row;
            align-items: center;
            /* border: 2px solid red; */
        }
            .MainFooter__left-contact-container p{
                color: #000;
                font-family: Montserrat;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 600;
                line-height: 0.875rem; 
                text-transform: uppercase;
                /* border: 3px solid blue; */
                margin-bottom: 0;
                
            }
        .MainFooter__left-email-container{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            /* border: 2px solid blue; */
        }
            .MainFooter__left-email-container p{
                color: #000;
                font-family: Montserrat;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 600;
                line-height: 0.875rem; 
                text-transform: lowercase;
                margin-bottom: 0;
            }
    
    .MainFooter__center-container{
        /* border: 3px solid blue; */
        width: 35%;
    }

            .MainFooter__center-nav ul{
                list-style-type: none;
            }
                .MainFooter__center-nav ul li a:hover{
                    border-bottom: 3px solid rgb(255, 255, 255);
                }
                .MainFooter__center-nav ul li a{
                    text-decoration: none;
                    color: #000;
                    color: #000;
                    font-family: Montserrat;
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1.05rem; 
                    text-transform: uppercase;
                }
    

    
    .MainFooter__right-container{
        /* border: 3px solid red; */
        width: 35%;
    }

            .MainFooter__right-nav ul {
                /* border: 2px solid green; */
                list-style-type: none;
            
            }
                .MainFooter__right-nav ul li a:hover{
                    border-bottom: 3px solid rgb(255, 255, 255);
                    
                }
                .MainFooter__right-nav ul li a {
                    text-decoration: none;
                    color: #000;
                    font-family: Montserrat;
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1.05rem; 
                    text-transform: uppercase;
                }
                   
                   

.MainFooter__main-legal-description-container{
    /* border: 3px solid purple; */
    width: 45%;
}
    .MainFooter__legal-description-container{
        /* border: 3px solid black; */
        display: flex;
        flex-direction: column;
        padding-top: .5em;
        padding-left: 1.5em;
        margin-top: 1.5em;
        margin-left: 4em;

    }
        .MainFooter__legal-description-container{
            color: #00030A;
            font-size: 0.9rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.60688rem; 
        }

    .MainFooter__right-and-cards-icon-container{
        /* border: 6px solid blue; */
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-left: 5.5em;

    }   
        .MainFooter__copy-right-container{
            /* border: 3px solid green; */
            display: flex;
            flex-direction: column;
            justify-content: center;
            justify-items: center;
        }   
            .MainFooter__copy-right-container p {
                /* border: 2px solid white; */
                margin-top: 1em;
            }
    .MainFooter__card-icon-container{
        /* border : 3px solid black; */
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
        .MainFooter__credit-card-icons{

        }   

.MainFooter__legal-description-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin: 0;
}
    .MainFooter__legal-description{
        width: 95%;
    }       


.MainFooter__business-address{
    font-family: 'Lato', sans-serif;
    font-size: .75rem;
    font-weight: 700;
    text-align: center;
   /*  border: 3px solid red; */
    width: 100%;
    max-width: 25em;
}
/*==================== RESPONSIVE =========================*/
@media (max-width: 575.98px) {
    .MainFooter__footer{
        /* border: 3px solid rgb(0, 0, 0); */
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1em .5em .5em .5em;
    }
    .MainFooter__main-footer-container{
       /*  border: 3px solid white; */
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .MainFooter__left-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0;
    }
    .MainFooter__center-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .MainFooter__menus-container{
       /*  border: 3px solid red; */
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        padding: 0;
        margin: 0;
    }
        .MainFooter__center-container{
            /* border: 3px solid wheat; */
            width: auto;
        }
            .MainFooter__center-menu-container{
                /* border: 3px solid purple; */
                padding: 0;
                margin:0;
            }
                .MainFooter__center-nav ul{
                    padding: 0;
                }
                    .MainFooter__center-nav ul li a {
                        font-family: 'Montserrat', sans-serif;
                        font-size: 0.75rem;
                        font-weight: 600;
                        line-height: 1rem;

                    }
        .MainFooter__right-container{
            /* border: 3px solid green; */
            width: auto;
        }
            .MainFooter__right-menu-container{
               /*  border: 3px solid black; */
            }
                .MainFooter__right-nav ul{
                    padding: 0;
                }
                    .MainFooter__right-nav ul li a{
                        font-family: 'Montserrat', sans-serif;
                        font-size: 0.75rem;
                        font-weight: 600;
                        line-height: 1rem;
                    }


    .MainFooter__main-legal-description-container{
        /* border: 3px solid blue; */
        width: 100%;
    }
        .MainFooter__legal-description-container{
            /* border: 3px solid rgb(0, 0, 0); */
        }
        .MainFooter__legal-description-container{
           /*  border: 3px solid green; */
        }
            .MainFooter__legal-description {
                /* border: 3px solid red; */
                width: 100%;
                padding-left: 1.5em;
            }
                .MainFooter__legal-description p{
                    font-family: 'Lato', sans-serif;
                    font-size: 0.85rem;
                    font-weight: 400;
                    line-height: 1.5rem;
                    
                }
        .MainFooter__right-and-cards-icon-container{
            /* border: 3px solid black; */
        }
            .MainFooter__copy-right-container p{
                text-align: center;
                font-family: 'Lato', sans-serif;
                font-weight: 700;
                font-size: 0.75rem;
                line-height: 1.5rem;

            }

    .MainFooter__right-and-cards-icon-container{
       /*  border: 3px solid red; */
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0;
        padding: 0;
    }



    
}

@media (min-width: 576px) and (max-width: 767.98px) {
.MainFooter__footer{
  /*   border: 3px solid red; */
    display: flex;
    flex-direction: column;
    padding: 1em;
    
}
    .MainFooter__main-footer-container{
       /*  border: 3px solid blue; */
        width: 100%;
        display: flex;
        flex-direction: row;
    }
        .MainFooter__left-container{
           /*  border: 3px solid white; */
            width: 100%;
            margin: 0;
        }
        .MainFooter__menus-container{
           /*  border: 3px solid red; */
            width: 100%;
            display: flex;
            flex-direction: row;

        }
            .MainFooter__center-container{
                /* border: 3px solid red; */
                width: 60%;
            }
                .MainFooter__center-menu-container{
                    /* border: 3px solid white; */
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
            
                }
                    .MainFooter__center-nav ul{
                       /*  border: 3px solid blue; */
                        padding-left: .5em;
                    }
            .MainFooter__right-container{
                width: 40%;
            }
                .MainFooter__right-menu-container{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                .MainFooter__right-nav ul{
                  /*   border: 3px solid green; */
                    padding-left: 1em;
                  
                }






    .MainFooter__main-legal-description-container{
        /* border: 3px solid green; */
        width: 100%;
        display: flex;
        flex-direction: row;
      

    }
        .MainFooter__legal-description-container{
           /*  border: 3px solid red; */
            width: 60%;
        }
        .MainFooter__right-and-cards-icon-container{
           /*  border: 3px solid black; */
            margin: 0;
            width: 40%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
            .MainFooter__copy-right-container p{
                font-size: .7rem;
            }
            .MainFooter__card-icon-container{
                width: 100%;
            }
                .MainFooter__credit-card-icons{
                    width: 100%;
                }
                    .MainFooter__credit-card-icons img{
                        width: 100%;
                    }
}


@media (min-width: 768px) and (max-width: 991.98px) { 
    .MainFooter__footer{
        /*   border: 3px solid red; */
          display: flex;
          flex-direction: column;
          padding: 1em;
          
      }
          .MainFooter__main-footer-container{
             /*  border: 3px solid blue; */
              width: 100%;
              display: flex;
              flex-direction: row;
          }
              .MainFooter__left-container{
                 /*  border: 3px solid white; */
                  width: 100%;
                  margin: 0;
              }
              .MainFooter__menus-container{
                 /*  border: 3px solid red; */
                  width: 100%;
                  display: flex;
                  flex-direction: row;
      
              }
                  .MainFooter__center-container{
                      /* border: 3px solid red; */
                      width: 60%;
                  }
                      .MainFooter__center-menu-container{
                          /* border: 3px solid white; */
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          justify-content: center;
                  
                      }
                          .MainFooter__center-nav ul{
                             /*  border: 3px solid blue; */
                              padding-left: .5em;
                          }
                  .MainFooter__right-container{
                      width: 40%;
                  }
                      .MainFooter__right-menu-container{
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          justify-content: center;
                      }
                      .MainFooter__right-nav ul{
                        /*   border: 3px solid green; */
                          padding-left: 1em;
                        
                      }
      
      
          .MainFooter__main-legal-description-container{
              /* border: 3px solid green; */
              width: 100%;
              display: flex;
              flex-direction: row;
            
      
          }
              .MainFooter__legal-description-container{
                 /*  border: 3px solid red; */
                  width: 60%;
              }
              .MainFooter__right-and-cards-icon-container{
                 /*  border: 3px solid black; */
                  margin: 0;
                  width: 40%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
              }
                  .MainFooter__copy-right-container p{
                      font-size: .9rem;
                  }
                  .MainFooter__card-icon-container{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  }
                      .MainFooter__credit-card-icons{
                        width: 100%;
                        max-width: 15em;
                      }
                          .MainFooter__credit-card-icons img{
                              width: 100%;
                          }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .MainFooter__footer{
        /*   border: 3px solid red; */
          display: flex;
          flex-direction: column;
          padding: 1em;
          
      }
          .MainFooter__main-footer-container{
             /*  border: 3px solid blue; */
              width: 100%;
              display: flex;
              flex-direction: row;
          }
              .MainFooter__left-container{
                 /*  border: 3px solid white; */
                  width: 100%;
                  margin: 0;
              }
              .MainFooter__menus-container{
                 /*  border: 3px solid red; */
                  width: 100%;
                  display: flex;
                  flex-direction: row;
      
              }
                  .MainFooter__center-container{
                      /* border: 3px solid red; */
                      width: 60%;
                  }
                      .MainFooter__center-menu-container{
                          /* border: 3px solid white; */
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          justify-content: center;
                  
                      }
                          .MainFooter__center-nav ul{
                             /*  border: 3px solid blue; */
                              padding-left: .5em;
                          }
                  .MainFooter__right-container{
                      width: 40%;
                  }
                      .MainFooter__right-menu-container{
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          justify-content: center;
                      }
                      .MainFooter__right-nav ul{
                        /*   border: 3px solid green; */
                          padding-left: 1em;
                        
                      }
      
      
      
      
      
      
          .MainFooter__main-legal-description-container{
              /* border: 3px solid green; */
              width: 100%;
              display: flex;
              flex-direction: row;
            
      
          }
              .MainFooter__legal-description-container{
                 /*  border: 3px solid red; */
                  width: 60%;
              }
              .MainFooter__right-and-cards-icon-container{
                 /*  border: 3px solid black; */
                  margin: 0;
                  width: 40%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
              }
                  .MainFooter__copy-right-container p{
                      font-size: .9rem;
                  }
                  .MainFooter__card-icon-container{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  }
                      .MainFooter__credit-card-icons{
                        width: 100%;
                        max-width: 15em;
                      }
                          .MainFooter__credit-card-icons img{
                              width: 100%;
                          }
}


@media (min-width: 1200px) {
    .MainFooter__footer{
        /* border: 3px solid red; */
        padding: 2em .5em .5em 1em;
    }
        .MainFooter__main-footer-container{
           /*  border: 3px solid green; */
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            max-width: 45em;
        }
            .MainFooter__left-container{
                /* border: 3px solid orange; */
                padding: 0;
            }
            .MainFooter__menus-container{
               /*  border: 3px solid blue; */
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                margin-right: 3em;
                width: 100%;
            }
        .MainFooter__center-container{
            /* border: 3px solid red; */
            width: 50%;
        }
        .MainFooter__right-container{
            /* border: 3px solid blue; */
            width: 30%;
        }
      
        .MainFooter__main-legal-description-container{
            /* border: 3px solid blue; */
        }
        .MainFooter__right-and-cards-icon-container{
            /* border: 3px solid red; */
            width: 100%;
            padding: 0;
            margin: 0;
        }
        .MainFooter__copy-right-container p{
            font-size: .9rem;
        }

    
}


@media (min-width: 1400px) { 


}



/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .AssociatedBrands__main-container{
     /* border: 3px solid red; */
    }
     .AssociatedBrand__main-title-container{
         /* border: 2px solid blue; */
     }
     .AssociatedBrand__icons-contianer{
         /* border: 2px solid green; */
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
     }
     .AssociatedBrand__brand-one, .AssociatedBrand__brand-two,
     .AssociatedBrand__brand-three, .AssociatedBrand__brand-four,
     .AssociatedBrand__brand-five{
         /* border: 2px solid red; */
         width: 8em;
         height: auto;
     }
         .AssociatedBrand__icon{
             width: 100%;
             height: auto;
         }
  }